import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';
import '../../assets/css/allergy-amulet.css';

import allergyAmulet from '../../assets/images/allergy-amulet/allergy-amulet-featured-banner.webp';
import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import allergyAmuletOne from '../../assets/images/allergy-amulet/allergy-amulet-1.webp';
import allergyAmuletTwo from '../../assets/images/allergy-amulet/allergy-amulet-2.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class AllergyAmulet extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={allergyAmulet} alt="banner" />
          </section>
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  World’s Fastest Consumer Food Allergen Sensor
                </div>
                <div className="subtitle section-margin">
                  Taking food safety testing out of labs and into the hands of consumers
                </div>
                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Develop a fast and portable food allergen and ingredient sensor that would
                      enable people with allergies to quickly test their food.
                    </p>
                  </div>
                  <div className="mini-card ">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Designed a sleek and lightweight product in 4 months that could deliver test
                      results for the 8 most common allergens within 60 seconds.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      Client was able to raise pre-seed funding of $3.3 million in 2020, followed by
                      an additional $0.8 million in the seed round the following year.
                    </p>
                  </div>
                </div>
                <div className="col-11 mr-auto ml-auto ">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      In a world where over 550 million people are affected by food allergens, this
                      client wanted to develop a portable and user-friendly food detection device
                      that would transform the frontiers of the food safety industry. This US-based
                      company had developed a proprietary technology made up of thin films that
                      would match and bind to the target allergenic molecules.
                    </p>
                    <br />
                    <p className="description">
                      The client was now looking to build a processing unit that would generate
                      unique signals to alert consumers about an allergen’s presence. They wanted
                      Tintash to develop a sleek, compact, and lightweight sensing device that users
                      could easily wear or carry around.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      The client engaged us as their development partner right at the beginning of
                      their product journey. The project started with a discovery phase during which
                      our team helped refine the client’s requirements, define their major
                      milestones, and finalize the budgeting estimates. Tintash was to lead through
                      all the stages of product prototyping, including the hardware and firmware
                      designs and product development, all the way to a production-ready design.
                    </p>
                    <br />
                    <p className="description">
                      Our in-house hardware team began work on the schematic, PCB design and layout
                      and had a small batch of boards fabricated from China. The next step included
                      board bring up and modular testing before dispatching the fully functional and
                      verified boards for testing by the client in the US.
                    </p>
                    <br />
                    <p className="description">
                      The client wanted a display on the device and to include a battery that would
                      last at least a couple of weeks. At the same time however, they wanted a
                      product that was easy to carry around and could be categorized as a jewelry
                      item. The challenge facing our hardware team was to cater to every product
                      requirement by including and fitting all the various components while ensuring
                      the final design was compact, lightweight, and sleek.
                    </p>
                    <br />
                    <p className="description">
                      To meet these design requirements, we selected one of the more compact-sized
                      Nordic semiconductors. To support a long life, we added a battery that was
                      completely rechargeable within two hours and could last two weeks. We also
                      added an OLED graphical display on the device that would signal allergens to
                      users.
                    </p>
                  </div>
                  <section className="container p-0 section-margin">
                    <div className="picture-outer">
                      <img className="allergy-amulet-subpic" src={allergyAmuletTwo} alt="bbqguru" />
                      <img className="allergy-amulet-subpic" src={allergyAmuletOne} alt="bbqguru" />
                    </div>
                  </section>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      On the firmware design and development front, our team created a custom
                      Bluetooth service architecture to ensure optimal exchange of data between the
                      deep processing unit and the client’s associated mobile app. Since the client
                      had not yet developed this app, our team created a dummy app (a different app
                      was later developed and launched independently by the client) to determine
                      feasibility and conduct firmware testing and debugging.
                    </p>
                    <br />
                    <p className="description">
                      Tintash also assigned a technical project manager (TPM) who was responsible
                      for sprint planning and execution, defining milestones, and ensuring their
                      timely delivery. The TPM maintained direct contact with the client’s product
                      team to provide consultation, gather feedback, and schedule demos of the
                      completed milestones.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      The client’s detection platform combined the utilization of molecularly
                      imprinted polymer (MIP) technology with an electrochemical system to identify
                      specific allergenic components. This platform comprised two components: a
                      compact reader, jointly developed by Tintash, and a sampler independently
                      created by the client.
                    </p>
                    <br />
                    <p className="description">
                      The platform worked in this simple way: individuals would initially gather a
                      sample of their food using the sampler, which would subsequently be inserted
                      into the reader for testing. Within a span of 60 seconds, the test results
                      would be displayed on the device’s screen. Users also had the option to
                      connect the device with the accompanying app to store their test results.
                    </p>
                    <br />
                    <p className="description">
                      The prototype we helped develop could run quick and accurate tests for the top
                      eight most common food allergens (peanuts, tree nuts, fish, shellfish, wheat,
                      eggs, milk, and soy) as well as gluten and sesame. Our efforts which lasted
                      almost four months enabled the client to subsequently raise around $4.1
                      million in pre-seed/seed funding over the next two years.
                    </p>
                    <br />
                    <p className="description">
                      The successful fundraising put the client on course to expand its product
                      offerings and cover more allergens, manufacture beta units, launch pre-orders,
                      and enter into partnerships with restaurants and companies. That company today
                      is well on its way to achieving its vision of offering people with allergies a
                      feeling of freedom and security.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default AllergyAmulet;
